import React from "react"
import { graphql } from "gatsby"
import Pois from "../components/pois/page"

const ThingsToDoPage = ({ data, location }) => {
  return <Pois title="In de buurt" data={data} location={location} />
}

export const pageQuery = graphql`
  query ThingsToDoQuery {
    site {
      siteMetadata {
        house
        title
      }
    }
    allContentfulPoi(
      filter: { type: { nin: ["Café", "Restaurant", "Supermarkt"] } }
    ) {
      nodes {
        url
        title
        type
        location {
          lat
          lon
        }
        image {
          fluid(maxWidth: 1024, maxHeight: 768) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        body {
          childMarkdownRemark {
            excerpt
          }
        }
      }
    }
  }
`

export default ThingsToDoPage
